import React, { FC, ReactNode } from 'react';

import Layout from '~/components/Layout';
import requireUser, { UserProps } from '~/utils/requireUser';

interface TemplateUserProps {
  collectionName?: string;
  docId?: string;
  secondary?: ReactNode;
  title?: string;
}

const TemplateUser: FC<TemplateUserProps & UserProps> = ({
  collectionName,
  children,
  docId,
  secondary,
  title,
}) => (
  <Layout
    collectionName={collectionName}
    docId={docId}
    kind="user"
    showNavigation
  >
    <div className="mt-6 mx-auto">
      {title && <h1 className="text-xl font-bold mb-6">{title}</h1>}
      {undefined !== secondary && (
        <>
          {secondary}
          <hr className="my-6" />
        </>
      )}

      <div> {children}</div>
    </div>
  </Layout>
);
export default requireUser(TemplateUser);
